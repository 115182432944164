<template>
  <div class="disbursement">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Giải ngân công nợ'">
      <template v-slot:toolbar> </template>
      <template v-slot:preview>
        <p><b>Ngày nhập: </b>{{ importDate }}</p>
        <div class="mt-1">
          <b-row>
            <b-col>
              <span> <b>Bước 1:</b> Vui lòng chọn loại dữ liệu cần nhập </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b-form-select
                v-model="debtSelected"
                :options="optionsDebt"
                size="sm"
                class="mt-3 select-style"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >Chọn loại giải ngân</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-form-select
                v-model="disbureseSelected"
                :options="optionsDisburese"
                size="sm"
                class="mt-3 select-style"
                :disabled="isDisableHtml('disburese')"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >Chọn nhà giải ngân</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
          </b-row>
        </div>
        <div v-if="isDisplayHtml('import-excel')" class="mt-4">
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Nhập dữ liệu bằng excel </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <UploadFileMulter
                v-on:send-file="getDataFromExcel"
                v-on:clear-file="clearFileExcel"
                :cmdUrl="'disbursements/uploads/'"
              />
              <span v-if="fileName"
                >Tên file: <b>{{ fileName }}</b>
              </span>
            </b-col>
          </b-row>
        </div>
        <div v-show="isDisableHtml('file-has-data')" class="mt-4">
          <b-row>
            <b-col>
              <div class="mt-10" v-show="isDisableHtml('file-has-data')">
                <hr
                  class="hr-text"
                  data-content="Danh sách giải ngân"
                  style="font-weight: 600"
                />
                <div class="title-sum">
                  <ul class="list-inline d-flex">
                    <li>
                      <b
                        >Tổng tiền:
                        <span class="text-success">{{
                          convertPrice(formattedTotalPrice)
                        }}</span>
                      </b>
                    </li>
                  </ul>
                </div>
                <div class="table-responsive">
                  <b-table
                    responsive
                    bordered
                    hover
                    :items="disburseRaws"
                    :fields="disburseRawFields"
                    :per-page="10"
                    :current-page="currentPage"
                  >
                  </b-table>
                </div>

                <b-col class="row mt-3">
                  <b>
                    Tổng số dòng:
                    {{ disburseRaws ? disburseRaws.length : 0 }}
                  </b>
                </b-col>
                <b-pagination
                  v-show="isDisableHtml('file-has-data', 10)"
                  v-model="currentPageRaw"
                  :total-rows="rowRaws()"
                  :per-page="10"
                  align="right"
                ></b-pagination>
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          v-show="mode === 'update' || isDisableHtml('file-has-data')"
          class="mt-4"
        >
          <b-row>
            <b-col>
              <span> <b>Bước 3:</b> Map dữ liệu </span>
              <p class="mt-2">
                Giải ngân : <b>{{ getNameDebtSelected }}</b>
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="4">
              <div>
                <hr
                  class="hr-text"
                  data-content="Bảng kết nối dữ liệu"
                  style="font-weight: 600"
                />
                <b-button
                  style="width: 100px"
                  class="mb-4"
                  variant="secondary"
                  size="sm"
                  @click="onMatchingDataExcel"
                  :disabled="!isDisableHtml('file-has-data')"
                >
                  <strong>Lọc dữ liệu</strong>
                </b-button>
                <b-table
                  bordered
                  hover
                  :fields="disburseConfigFields"
                  :items="disburseConfigs"
                >
                  <template
                    v-for="field in getHeaderTemplate"
                    v-slot:[`cell(${field.key})`]="data"
                  >
                    <b-form-select
                      :key="field.key"
                      v-model="data.item.reference"
                      :options="getOptionsExcelTemplate"
                      size="sm"
                      class="select-style"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >Chọn trường dữ liệu</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </template>
                  <template v-slot:cell(source)="data">
                    {{ data.item.source }}
                    <span v-if="data.item.required" class="d-block">
                      <i
                        class="text-danger flaticon2-information"
                        v-b-tooltip.hover
                        title="Bắt buộc"
                      ></i>
                      &nbsp;
                    </span>
                  </template>
                </b-table>
              </div>
              <b-row>
                <b-col>
                  <span> <b>Bước 4:</b> Nhập thông tin </span>
                </b-col>
              </b-row>
              <div class="mt-2">
                <b-row>
                  <b-col>
                    <b-input
                      size="sm"
                      placeholder="Tên giải ngân công nợ"
                      autocomplete="off"
                      v-model="disburseName"
                  /></b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <b-form-textarea
                      size="sm"
                      class="input-style"
                      placeholder="Thêm ghi chú"
                      :rows="3"
                      :max-rows="3"
                      v-model="disburseNote"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col v-if="disburseConvertedsBackUp.length">
                    <b-overlay :show="isBusy" rounded block opacity="0.6">
                      <template #overlay>
                        <div class="d-flex align-items-center">
                          <b-spinner
                            variant="light"
                            small
                            type="grow"
                          ></b-spinner>
                        </div>
                      </template>
                      <b-button
                        block
                        variant="primary"
                        size="sm"
                        class="mt-6"
                        :disabled="!disburseConvertedsBackUp.length"
                        @click="onUpsertDisburse"
                      >
                        <strong>{{
                          mode === 'update' ? 'Cập nhật' : 'Tạo mới'
                        }}</strong>
                      </b-button>
                    </b-overlay>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="8">
              <div v-if="isDisplayHtml('match-data')">
                <hr
                  class="hr-text"
                  data-content="Danh sách kết nối dữ liệu"
                  style="font-weight: 600"
                />

                <b-row>
                  <b-col>
                    <div>
                      <b-overlay :show="isMatchDocument" rounded opacity="0.6">
                        <template #overlay>
                          <div class="d-flex align-items-center">
                            <b-spinner
                              variant="light"
                              small
                              type="grow"
                            ></b-spinner>
                          </div>
                        </template>
                        <b-button
                          style="width: 120px"
                          class="mb-4 mr-4"
                          variant="warning"
                          size="sm"
                          @click="onPopupModalDocument"
                        >
                          <strong>
                            <i
                              style="font-size: 1rem"
                              class="fas fa-search mr-1"
                            ></i
                            >Tìm chứng từ</strong
                          >
                        </b-button>
                        <b-dropdown size="sm" class="mb-4" right>
                          <template slot="button-content">
                            <i style="font-size: 1rem" class="fas fa-cog"></i>
                            <span class="font-weight-bolder">Xuất excel</span>
                          </template>
                          <b-dropdown-item @click="onExportToDebt">
                            <i class="far fa-file-excel mr-1"></i>
                            <span> Công nợ</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-overlay>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div v-show="disburseConvertedsBackUp.length !== 0">
                      <div class="table-responsive">
                        <b-table
                          show-empty
                          responsive
                          bordered
                          hover
                          :items="disburseConverteds"
                          :fields="disburseConvertedFields"
                          :per-page="10"
                          :current-page="currentPage"
                        >
                          <template #empty>
                            <div class="text-center">Không có dữ liệu</div>
                          </template>
                          <template #cell(totalAmount)="data">
                            {{ convertPrice(data.item.totalAmount) }}
                          </template>
                          <template #cell(entities)="data">
                            <div
                              v-if="
                                data.item.entities && data.item.entities.length
                              "
                            >
                              <div class="d-table">
                                <div
                                  v-for="(entry, index) in data.item.entities"
                                  :key="index"
                                >
                                  <div
                                    class="pt-2 d-table-row"
                                    :class="index > 0 ? 'border-top' : ''"
                                  >
                                    <div class="d-table-cell pr-2 align-middle">
                                      {{
                                        entry.entities
                                          ? entry.entities.billNumber
                                          : ''
                                      }}
                                      <small class="d-block">{{
                                        entry.entitiesTypeName
                                      }}</small>
                                    </div>
                                    <div class="d-table-cell pl-3 pr-2">
                                      <b class="text-left d-block">
                                        {{ entry.paymentName }}</b
                                      >
                                      <span class="text-left d-block">
                                        {{ entry.paymentAmount }}</span
                                      >
                                    </div>
                                    <div class="d-table-cell">
                                      <span class="text-left d-block">
                                        {{
                                          entry.entities
                                            ? entry.entities.customerName
                                            : ''
                                        }}</span
                                      >
                                      <span class="text-left d-block">
                                        {{
                                          entry.entities
                                            ? entry.entities.customerMobile
                                            : ''
                                        }}</span
                                      >
                                    </div>
                                    <div class="d-table-cell align-middle">
                                      <i
                                        v-if="data.item.entities.length > 1"
                                        class="
                                          fa fa-times-circle
                                          text-danger
                                          cursor-pointer
                                          ml-2
                                        "
                                        @click="
                                          removeItem(data.item, entry, 'detail')
                                        "
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        v-if="entry.noMatching"
                                        :title="entry.noMatchingName"
                                        class="
                                          fa fa-exclamation-triangle
                                          text-warning
                                          d-block
                                          ml-2
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template #cell(statusAttribute)="data">
                            <b-badge
                              :variant="
                                data.item.statusAttribute
                                  ? data.item.statusAttribute.label
                                  : 'danger'
                              "
                              >{{
                                data.item.statusAttribute
                                  ? data.item.statusAttribute.statusName
                                  : 'Lỗi'
                              }}</b-badge
                            >
                            <span
                              class="d-block font-weight-bolder"
                              v-if="
                                data.item.entities
                                  ? data.item.entities.length
                                  : false
                              "
                            >
                              ({{
                                data.item.entities
                                  ? data.item.entities.length
                                  : ''
                              }})</span
                            >
                          </template>
                          <template #cell(actions)="data">
                            <i
                              class="
                                d-block
                                fa fa-trash
                                text-danger
                                cursor-pointer
                                ml-2
                              "
                              @click="removeItem(data.item, null, 'header')"
                              aria-hidden="true"
                            ></i
                          ></template>
                        </b-table>
                      </div>

                      <b-col class="row mt-3">
                        <div class="title-sum">
                          <ul class="list-inline d-flex">
                            <li class="mr-2">
                              <b
                                >Tổng tiền:
                                <span class="text-success">{{
                                  convertPrice(formattedTotalPrice)
                                }}</span>
                              </b>
                            </li>
                            <li
                              class="mr-2 cursor-pointer"
                              @click="filterDisbursement()"
                            >
                              <b>
                                Tổng số dòng:
                                <span lass="text-success">
                                  {{
                                    disburseConvertedsBackUp
                                      ? disburseConvertedsBackUp.length
                                      : 0
                                  }}
                                </span>
                              </b>
                            </li>
                            <li
                              class="mr-2 cursor-pointer"
                              @click="filterDisbursement('valid')"
                            >
                              <b
                                >Khớp một lệnh:
                                <span class="text-primary">{{
                                  convertPrice(countValidDisbursement)
                                }}</span>
                              </b>
                            </li>
                            <li
                              class="mr-2 cursor-pointer"
                              @click="filterDisbursement('valid-mul')"
                            >
                              <b
                                >Khớp nhiều lệnh:
                                <span class="text-primary">{{
                                  convertPrice(countValidMulDisbursement)
                                }}</span>
                              </b>
                            </li>
                            <li
                              class="mr-2 cursor-pointer"
                              @click="filterDisbursement('invalid')"
                            >
                              <b
                                >Không tìm thấy:
                                <span class="text-danger">{{
                                  convertPrice(countInValidDisbursement)
                                }}</span>
                              </b>
                            </li>
                            <li
                              class="mr-2 cursor-pointer"
                              @click="filterDisbursement('no-match-document')"
                            >
                              <b
                                >Không khớp giải ngân:
                                <span class="text-warning">{{
                                  convertPrice(noMatchingDocument)
                                }}</span>
                              </b>
                            </li>
                          </ul>
                        </div>
                      </b-col>
                      <b-pagination
                        v-show="disburseConverteds.length > 10"
                        v-model="currentPage"
                        :total-rows="rows()"
                        :per-page="10"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
    <PopupDisburementDocument
      :isMatchDocument="isMatchDocument"
      v-on:match-data-document="onMatchingDataDocument"
    />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import PopupDisburementDocument from './modal/PopupDisburementDocument';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import { mapGetters } from 'vuex';
import {
  convertArrayToObject,
  convertPrice,
  cloneDeep,
  makeToastFaile,
} from '@/utils/common';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import fileDownload from '@/utils/file-download';
import { makeToastSuccess } from '../../../utils/common';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  data() {
    return {
      title: 'Công nợ quẹt thẻ hóa đơn theo ngày',
      debtSelected: null,
      disbureseSelected: null,
      defaultOptions: [],
      defaultDisburseConfigFields: [
        {
          key: 'source',
          label: 'Hệ thống',
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'reference',
          label: 'Excel',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-center',
        },
      ],
      disburseConverteds: [],
      disburseConvertedFields: [],
      disburseConfigs: [],
      disburseConfigFields: [],
      optionsExcelHeaders: [],
      disburseRaws: [],
      disburseRawFields: [],
      currentPage: 1,
      currentPageRaw: 1,
      disburseName: null,
      disburseNote: null,
      mode: null,
      importDate: moment().format('DD/MM/YYYY'),
      fileName: null,
      disburseConvertedsBackUp: [],
      disburseId: null,
      isMatchDocument: false,
      isBusy: false,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    UploadFileMulter,
    PopupDisburementDocument,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Giải ngân công nợ' }]);
  },
  beforeDestroy() {},
  created() {
    this.getConfigDisburse();
    const id = this.$route.params.id;
    if (id) {
      this.mode = 'update';
      this.disburseId = id;
      this.getDisburseById(id);
    }
  },
  methods: {
    convertPrice,
    isDisplayHtml(type) {
      switch (type) {
        case 'import-excel': {
          return this.debtSelected && this.disbureseSelected;
        }
        case 'match-data': {
          return this.disburseConvertedsBackUp.length;
        }
      }
    },
    isDisableHtml(type, value) {
      switch (type) {
        case 'disburese': {
          return !this.debtSelected;
        }
        case 'file-has-data': {
          return this.disburseRaws.length > (value || 0) ? true : false;
        }
      }
    },
    rows() {
      return this.disburseConverteds.length;
    },
    rowRaws() {
      return this.disburseRaws.length;
    },
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;
      this.disburseRaws = [...data.rows].map((item) => {
        return {
          ...item,
          onLoading: false,
        };
      });
      const headers = data.headers;
      this.optionsExcelHeaders = Object.keys(headers).reduce((acc, cur) => {
        const item = {
          value: cur,
          text: cur,
        };
        acc.push(item);
        return acc;
      }, []);
      this.disburseRawFields = Object.values(headers).reduce((acc, cur) => {
        const item = {
          key: cur.field,
          label: cur.field,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
          tdStyle: {
            ...thStyleCommon,
            width: '5%',
          },
        };

        acc.push(item);
        return acc;
      }, []);

      this.setConfigDisburse(data.headersRequired);
    },
    setConfigDisburse(headersRequired) {
      this.disburseConfigs = this.getHeadersRequired(headersRequired);
      this.disburseConfigFields = this.defaultDisburseConfigFields;
    },
    getHeadersRequired(headersRequired) {
      return Object.values(headersRequired).reduce((acc, cur) => {
        acc.push({
          source: cur.name,
          reference: null,
          refSource: cur.key,
          refKey: cur.refKey,
          required: cur.required,
        });
        return acc;
      }, []);
    },
    onMatchingDataExcel() {
      const [isValid, message] = this.validateMatchinDataExcel();
      if (!isValid) {
        makeToastFaile(message);
        return;
      }

      const importHeadersKey = convertArrayToObject(
        this.disburseConfigs,
        'reference'
      );
      const fieldsRaw = cloneDeep(this.disburseRawFields).filter((item) => {
        const headersInstead = importHeadersKey[item.key];
        if (headersInstead) {
          item.key = headersInstead.refSource;
          item.label = headersInstead.source;
          return item;
        }
      });

      this.disburseConvertedFields = cloneDeep(fieldsRaw);

      const disburseRaws = this.disburseRaws.map((element) => {
        const item = {
          id: this.$uuid.v4(),
        };
        for (const [key, value] of Object.entries(element)) {
          if (key && key.length > 0) {
            const headersInstead = importHeadersKey[key];
            if (headersInstead) {
              item[headersInstead.refSource] = value;
            }
          }
        }
        return item;
      });

      this.disburseConverteds = cloneDeep(disburseRaws);
      this.disburseConvertedsBackUp = cloneDeep(disburseRaws);
    },
    validateMatchinDataExcel() {
      for (const config of this.disburseConfigs) {
        if (config.required && !config.reference) {
          return [false, `Yêu cầu ${config.source}`];
        }
      }
      return [true, 'Hợp lệ'];
    },
    clearFileExcel() {
      this.disburseConverteds = [];
      this.disburseConvertedsBackUp = [];
    },
    onUpsertDisburse() {
      const data = {
        id: this.disburseId || null,
        disbureseSelected: this.disbureseSelected,
        debtSelected: this.debtSelected,
        disburseName: this.disburseName,
        disburseNote: this.disburseNote,
        disburseConverteds: this.disburseConvertedsBackUp,
        disburseConfigs: this.disburseConfigs,
        disburseConfigFields: this.disburseConfigFields,
        disburseConvertedFields: this.disburseConvertedFields,
        optionsExcelHeaders: this.optionsExcelHeaders,
        importDate: this.importDate,
        fileName: this.fileName,
      };
      const method = this.mode === 'update' ? 'put' : 'post';
      this.isBusy = true;
      ApiService[method]('/disbursements/', data)
        .then((response) => {
          const { status, data, message } = response.data;
          if (status) {
            if (this.mode !== 'update') {
              this.mode = 'update';
              this.disburseId = data.id;
              this.$router.push({
                name: 'update-disbursement',
                params: { id: data.id },
              });
            }
            makeToastSuccess(message);
          }
          this.isBusy = false;
        })
        .catch((err) => {
          this.isBusy = false;

          if (!err.response) {
            // network error
            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    onPopupModalDocument() {
      this.$bvModal.show('modal-disburement-document');
    },
    async onMatchingDataDocument(apiParams) {
      this.disburseConvertedFields.push(
        {
          key: 'entities',
          label: 'Chứng từ',
          thStyle: {
            ...thStyleCommon,
            width: '40%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'statusAttribute',
          label: 'Trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '2%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            ...thStyleCommon,
            width: '2%',
          },
          tdClass: 'text-center',
        }
      );
      const disburseConverteds = [];
      this.isMatchDocument = true;
      for (const data of cloneDeep(this.disburseConverteds)) {
        const result = await this.findDocumentBy(data.referenceCode, apiParams);
        if (result && result.status) {
          data.entities = result.data.dataset;
          data.statusAttribute = result.data.statusAttribute;
          disburseConverteds.push(data);
        }
      }
      this.isMatchDocument = false;
      this.disburseConverteds = disburseConverteds;
      this.disburseConvertedsBackUp = cloneDeep(disburseConverteds);
    },
    findDocumentBy(referenceCode, apiParams) {
      if (!referenceCode) {
        return makeToastFaile('Yêu cầu referenceCode');
      }
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';

      const fromDate = apiParams.fromDate
        ? moment(apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : null;
      const toDate = apiParams.toDate
        ? moment(apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : null;
      const compareWith = Number(apiParams.compareWith) || null;

      const params = {
        referenceCode: referenceCode,
        disbureseSelected: this.disbureseSelected,
        debtSelected: this.debtSelected,
        entityRefType: apiParams.entityRefType,
        compareWith: compareWith,
        fromDate: fromDate,
        toDate: toDate,
      };

      return ApiService.query(`/disbursements/find`, { params })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          return null;
        });
    },
    getDisburseById(id) {
      ApiService.get(`/disbursements/${id}`)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.disburseName = data.disburseName;
            this.disburseNote = data.disburseNote;
            this.disburseConverteds = data.disbursements;
            this.disburseConvertedsBackUp = data.disbursements;
            this.disburseConfigs = data.disburseConfigs;
            this.disburseConfigFields = data.disburseConfigFields;
            this.disburseConvertedFields = data.disburseConvertedFields;
            this.optionsExcelHeaders = data.optionsExcelHeaders;
            this.importDate = data.importDate;
            this.fileName = data.fileName;
            this.debtSelected = data.debtType;
            this.disbureseSelected = data.disbureseType;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    onExportToDebt() {
      const body = {
        disburses: this.disburseConvertedsBackUp,
      };

      ApiService.download(`/disbursements/convert`, body, {
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch((err) => {
          if (!err.response) {
            // network error

            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    getConfigDisburse() {
      ApiService.get(`/disbursements/config`)
        .then((response) => {
          const { data } = response.data;
          const parents = data;
          this.defaultOptions = parents;
        })
        .catch((err) => {
          if (!err.response) {
            // network error

            makeToastFaile(err.response);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    filterDisbursement(name) {
      switch (name) {
        case 'valid': {
          this.disburseConverteds = this.disburseConvertedsBackUp.filter(
            (item) => item.entities && item.entities.length === 1
          );
          break;
        }
        case 'valid-mul': {
          this.disburseConverteds = this.disburseConvertedsBackUp.filter(
            (item) => item.entities && item.entities.length > 1
          );
          break;
        }
        case 'invalid': {
          this.disburseConverteds = this.disburseConvertedsBackUp.filter(
            (item) => item.entities && item.entities.length === 0
          );
          break;
        }
        case 'no-match-document': {
          this.disburseConverteds = this.disburseConvertedsBackUp.filter(
            (item) => {
              return (
                (item.entities || []).filter((entity) => entity.noMatching)
                  .length > 0
              );
            }
          );
          break;
        }
        default: {
          this.disburseConverteds = cloneDeep(this.disburseConvertedsBackUp);
          break;
        }
      }
    },
    removeItem(header, detail, type) {
      switch (type) {
        case 'detail': {
          const indexHeader = this.disburseConverteds.findIndex(
            (item) => item.id === header.id
          );
          if (indexHeader > -1) {
            const indexDetail = this.disburseConverteds[
              indexHeader
            ].entities.findIndex((item) => item.id === detail.id);
            if (indexDetail > -1) {
              this.disburseConverteds[indexHeader].entities.splice(
                indexDetail,
                1
              );
            }
          }
          this.disburseConvertedsBackUp = cloneDeep(this.disburseConverteds);
          break;
        }
        case 'header': {
          const indexHeader = this.disburseConverteds.findIndex(
            (item) => item.id === header.id
          );
          if (indexHeader > -1) {
            this.disburseConverteds.splice(indexHeader, 1);
          }
          this.disburseConvertedsBackUp = cloneDeep(this.disburseConverteds);
          break;
        }
      }
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
    optionsDisburese() {
      const hasIndex = this.defaultOptions.findIndex(
        (op) => op.value === this.debtSelected
      );
      if (hasIndex < 0) {
        return;
      }
      return this.defaultOptions[hasIndex].childs;
    },
    optionsDebt() {
      return this.defaultOptions;
    },
    countValidDisbursement() {
      return this.disburseConvertedsBackUp.filter(
        (item) => item.entities && item.entities.length === 1
      ).length;
    },
    countValidMulDisbursement() {
      return this.disburseConvertedsBackUp.filter(
        (item) => item.entities && item.entities.length > 1
      ).length;
    },
    countInValidDisbursement() {
      return this.disburseConvertedsBackUp.filter(
        (item) => item.entities && item.entities.length === 0
      ).length;
    },
    noMatchingDocument() {
      return this.disburseConvertedsBackUp.filter((item) => {
        return (
          (item.entities || []).filter((entity) => entity.noMatching).length > 0
        );
      }).length;
    },
    formattedTotalPrice() {
      return this.disburseConverteds.reduce((result, item) => {
        return result + Number(item.totalAmount) || 0;
      }, 0);
    },
    getHeaderTemplate() {
      return this.disburseConfigFields;
    },
    getOptionsExcelTemplate() {
      return this.optionsExcelHeaders;
    },
    getNameDebtSelected() {
      if (!this.debtSelected) return '';
      let name = '';
      const optionsDebtSelected = this.defaultOptions.find(
        (option) => option.value === this.debtSelected
      );

      if (!optionsDebtSelected) {
        return name;
      }

      name += optionsDebtSelected.name;
      if (this.disbureseSelected) {
        const disburese = optionsDebtSelected.childs.find(
          (option) => option.value === this.disbureseSelected
        );
        if (disburese) {
          name += ` - ${disburese.name}`;
        }
      }

      return name;
    },
  },
  watch: {
    debtSelected(newValue) {
      if (!newValue) {
        this.disbureseSelected = null;
        return;
      }
    },
  },
};
</script>

<style lang="scss">
.disbursement {
  table {
    table-layout: fixed;
    width: auto;
    word-wrap: break-word;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
